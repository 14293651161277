import axios from "axios";

export async function fetchData(menu) {
  const headers = {
    "Content-Type": "application/json",
  };
  const url = `https://menuapi.mubatech.com/api/v1/getMenuItems/${menu}`;

  const data = await axios.get(url, { headers });
  return data.data;
}
