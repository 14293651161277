import telegram from "../images/telegramSend.png";
import hoursglass from "../images/hourglass.jpg";

export default function MenuError() {
  return (
    <div className="error-section">
      <div className="error-info-container">
        <div className="error-section__text-container">
          <h2>
            OPS..! <br /> Menu is not available
          </h2>
          <p>
            For reactivation menu please contact with{" "}
            <span className="purple">MubaTech</span> Staff
          </p>
        </div>
        <div className="error-section__image_container">
          <img
            src={hoursglass}
            alt="sadFace"
            className="error-section__image"
          />
        </div>
        <div className="error-section__contact">
          <a
          // href="https://t.me/MubaTechnology"
          >
            <img src={telegram} />
            <span>Contact with Muba tech</span>
          </a>
        </div>
      </div>
    </div>
  );
}
