import { BrowserRouter, Routes, Route } from "react-router-dom";

import "./App.css";
import Layout from "./Pages/Layout";
import MenuError from "./Pages/missing";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/">
          <Route path=":siteId" element={<Layout />}></Route>
          <Route path="/missing" element={<MenuError />}></Route>
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
