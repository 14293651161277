import Catagory from "../components/Catagory";
import MenuItems from "../components/MenuItems";
import sideArrow from "../images/sideArrow.svg";
import rightSideArrow from "../images/rightSideArrow.svg";
import options from "../images/options.svg";
import { useState, useEffect, useRef } from "react";
import PopUp from "../components/PopUp";
import cancelImg from "../images/cancel.svg";
import MenuItemsDropDown from "../components/MenuItemsDropDown";
import MenuFooter from "../components/MenuFooter";
import SlideShow from "../components/SlideShow";
import useIsInViewport from "../components/useIsInViewport";

export default function Menu({ siteData, siteId, lang, setLang, isFlipped }) {
  let [langPopUp, setLangPopUp] = useState(false);
  let [catSelected, setCatSelected] = useState(0);
  let [lineMotionToRight, setLineMotion] = useState(true);
  let [oldValue, setOldValue] = useState(0);
  let [modalOpen, setModalOpen] = useState(false);
  const singleCat = siteData.data.length === 1;

  const logo = `https://menuapi.mubatech.com/images/logos/${siteId}.png`;

  const firstCatRef = useRef(null);
  const lastCatRef = useRef(null);

  // let isInViewPort1 = useIsInViewport(firstCatRef);

  // console.log("isInViewPort-First:", isInViewPort1);

  // const isInViewPort2 = useIsInViewport(lastCatRef);
  // console.log("isInViewPort-Last:", isInViewPort2);

  function handleCarouselClick(condition) {
    setOldValue(catSelected);
    if (condition && catSelected < siteData.data.length - 1) {
      if (siteData.data[catSelected + 1].active) {
        setCatSelected(catSelected + 1);
      }
      // lineMotionToRight.current = true;
    } else if (!condition && catSelected > 0) {
      if (siteData.data[catSelected - 1].active) {
        setCatSelected(catSelected - 1);
      }

      // lineMotionToRight.current = false;
    }
  }

  useEffect(() => {
    if (oldValue > catSelected) {
      setLineMotion(false);
    }
    if (oldValue < catSelected) {
      setLineMotion(true);
    }

    if (!singleCat) {
      document.getElementById(`cat_${catSelected}`).focus();
    }
  }, [catSelected, oldValue]);

  let downArrow = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="13.508"
      height="8.432"
      viewBox="0 0 13.508 8.432"
    >
      <path
        id="Path_1"
        data-name="Path 1"
        d="M338.123,330.055l-6.754,6.754,6.754,6.754,1.678-1.678-3.388-3.388-1.689-1.689,5.076-5.076Z"
        transform="translate(-330.055 339.801) rotate(-90)"
        fill={`${siteData.colors.primary_font}`}
      />
    </svg>
  );

  return (
    <div
      className="menu-container"
      style={modalOpen ? { overflow: "hidden" } : null}
    >
      <div className="menu-header-container">
        <div>
          {
            // EMPTY DIV
          }
        </div>
        <div className="menu-logo-container">
          <img className="menu-logo" src={logo} alt={siteId + " Logo"} />
        </div>
        <div className="set-item-end">
          <div className="menu-language-select-arrow">{downArrow}</div>
          <button
            className="home-language-option"
            style={{ color: `${siteData.colors.primary_font}` }}
            onClick={() => setLangPopUp(true)}
          >
            {siteData.langs.map((e) => {
              if (e.surname === lang) {
                return e.name;
              }
            })}{" "}
          </button>
        </div>
      </div>

      {siteData.info.have_slider === "1" ||
      siteData.info.have_slider === 1 ||
      siteData.info.have_slider === true ? (
        <SlideShow siteId={siteId} num_slides={siteData.info.num_slides} />
      ) : null}
      {singleCat ? null : (
        <div
          className={`menu-catagories-container ${
            isFlipped ? "arabic_nrt_script" : ""
          }`}
        >
          <div className="menu-carousel-container">
            {catSelected === 0 ? null : (
              <button
                className="menu-carousel-button"
                onClick={() => {
                  handleCarouselClick(false);
                }}
              >
                <img src={sideArrow} alt="<" />
              </button>
            )}

            {
              // left blur
              // <div className="catagories-carousel-blur-left"></div>
            }
            <div className="menu-catagories">
              {siteData.data.map((item, index) => {
                return (
                  <button
                    key={item.title + index}
                    id={`cat_${index}`}
                    className="catagories-container-button"
                    ref={
                      index == 0
                        ? firstCatRef
                        : index == siteData.data.length - 1
                        ? lastCatRef
                        : null
                    }
                    onClick={async () => {
                      setOldValue(catSelected);
                      setCatSelected(index);
                    }}
                  >
                    <Catagory
                      name={item.name[lang]}
                      img={item.id}
                      colors={siteData.colors}
                      isFlipped={isFlipped}
                    />

                    <hr
                      className={`catagory-item-line `}
                      style={
                        catSelected === index
                          ? { borderColor: `${siteData.colors.primary_font}` }
                          : { borderColor: "transparent" }
                      }
                    />
                    {
                      // LINE MOTION LOGIC //
                      // catSelected === index
                      // ? lineMotionToRight
                      // ? "line-to-right"
                      // : "line-to-left"
                      // : ""
                    }
                  </button>
                );
              })}
            </div>
            {catSelected === siteData.data.length - 1 ? null : (
              <button
                className="menu-carousel-button carousel-button-right"
                onClick={() => {
                  handleCarouselClick(true);
                }}
              >
                <img src={rightSideArrow} alt=">" />
              </button>
            )}
          </div>
        </div>
      )}
      <div className="menu-catagory-info">
        <div
          className="menu-catagory-notes"
          style={{
            color: siteData.colors.primary_font,
            direction: isFlipped ? "rtl" : "ltr",
          }}
        >
          {siteData.data[catSelected].note
            ? siteData.data[catSelected].note[lang]
            : null}
        </div>
      </div>

      <div
        className="menu-item-container"
        style={{ direction: isFlipped ? "rtl" : "ltr" }}
      >
        {siteData.data[catSelected].items.map((item) => {
          if (
            item.have_sub === "1" ||
            item.have_sub === true ||
            item.have_sub === 1
          ) {
            return item.enable === "1" ||
              item.enable === true ||
              item.enable === 1 ? (
              <MenuItemsDropDown
                key={item.id}
                name={item.name[lang]}
                subs={item.sub}
                lang={lang}
                colors={siteData.colors}
                setModalOpen={setModalOpen}
              />
            ) : null;
          }

          return item.enable === 1 ||
            item.enable === "1" ||
            item.enable === true ? (
            <MenuItems
              key={item.id}
              id={item.id}
              name={item.name[lang]}
              note={item[lang]}
              lang={lang}
              havePrice={item.have_price}
              extraPrices={item.item_price}
              price={item.price}
              img={`https://menuapi.mubatech.com/images/items`}
              colors={siteData.colors}
              setModalOpen={setModalOpen}
            />
          ) : null;
        })}
      </div>

      <MenuFooter siteData={siteData} />
      <PopUp
        trigger={langPopUp}
        setTrigger={setLangPopUp}
        setModalOpen={setModalOpen}
      >
        <div
          className="popUp-box"
          style={{ background: `${siteData.colors.primary}` }}
        >
          <div
            style={{ color: `${siteData.colors.primary_font}` }}
            className="popUp-header"
          >
            <div></div>
            <p>Select language</p>
            <button
              className="popUp-close-button"
              onClick={() => setLangPopUp(false)}
            >
              <img src={cancelImg} alt="X" className="menu-button-img" />
            </button>
          </div>
          <div className="popUp-main">
            {siteData.langs.map((e) => {
              return (
                <button
                  key={e.name}
                  className="popUp-button"
                  style={{
                    color: `${
                      e.surname === lang
                        ? siteData.colors.secondary_font
                        : siteData.colors.primary_font
                    }`,
                    borderBottomColor: `${siteData.colors.primary_font}22`,
                  }}
                  onClick={() => {
                    setLangPopUp(false);
                    setLang(e.surname);
                  }}
                >
                  {e.name}
                </button>
              );
            })}
          </div>
        </div>
      </PopUp>
    </div>
  );
}
