import { useNavigate, Outlet, useParams } from "react-router-dom";
import { useEffect, useState } from "react";

import { fetchData } from "../services/fetchData";
import Loader from "../components/Loader";
import Menu from "./Menu";
import "./Page.css";

export default function Layout() {
  let { siteId } = useParams();
  let [siteData, setSiteData] = useState(null);
  let [lang, setLang] = useState("");
  let navigate = useNavigate();

  let logo = `https://menuapi.mubatech.com/images/logos/${siteId}.png`;

  useEffect(() => {
    async function getData() {
      let data = await fetchData(siteId);

      if (data.message === "failed") {
        navigate("/missing");
      } else {
        await setSiteData(data);

        setLang(data.langs[0].surname);
        document.title = `${siteId.toUpperCase()}`;
      }
    }
    getData();
  }, []);

  let isFlipped = lang === "ar" || lang === "ku" ? true : false;

  if (!siteData)
    return (
      <div className="loader-container">
        <Loader />
      </div>
    );
  return (
    <div
      className={`layout-container ${isFlipped ? "arabic_nrt_script" : ""}
        `}
      style={{
        background: `${siteData.colors.primary}`,
      }}
    >
      <Menu
        siteData={siteData}
        siteId={siteId}
        lang={lang}
        setLang={setLang}
        isFlipped={isFlipped}
      />
      <div className="layout-section">
        <img className="layout-img" src={logo} alt={siteId + " logo"} />
      </div>
    </div>
  );
}
