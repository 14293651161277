export default function Loader({ colors }) {
  return (
    <span
      className="loader"
      style={{
        borderColor: `${colors ? colors.secondary : "#F99802"} transparent`,
      }}
    ></span>
  );
}
