import { useRef } from "react";

import defaultImage from "../images/default.png";

import useIsInViewport from "./useIsInViewport";

export default function ItemViewer({
  img,
  name,
  note,
  lang,
  price,
  havePrice,
  extraPrices,
  colors,
}) {
  const ref1 = useRef(null);

  const isInViewPort1 = useIsInViewport(ref1);
  // console.log("isInViewPort:", isInViewPort1);

  return (
    <div
      className="item-viewer-container"
      style={{
        backgroundColor: `${colors.primary}`,
      }}
    >
      <div className="item-viewer-header">
        <img
          className="item-viewer-img"
          src={img}
          onError={({ currentTarget }) => {
            currentTarget.onerror = null;
            currentTarget.src = defaultImage;
          }}
          alt={name + " img"}
          loading="lazy"
        />
      </div>
      <div className="item-viewer-main">
        <p
          className="item-viewer-name"
          style={{ color: `${colors.primary_font}` }}
        >
          {name}
        </p>

        <p
          className="item-viewer-note"
          style={{ color: `${colors.secondary_font}` }}
        >
          {note}
        </p>

        {havePrice === "1" || havePrice === true || havePrice === 1 ? (
          <div className="item-viewer-price-wrapper">
            {extraPrices.map((e, i) => {
              return (
                <div key={e.id} className="item-viewer-price-container">
                  <p style={{ color: `${colors.primary_font}` }}> {e[lang]}</p>
                  <p
                    className="item-viewer-price"
                    style={{ color: `${colors.secondary_font}` }}
                  >
                    IQD {parseFloat(e.price).toLocaleString("en-US")}
                  </p>
                </div>
              );
            })}
            <p id="detectIfVisible"></p>
          </div>
        ) : (
          <p
            className="item-viewer-price"
            style={{ color: `${colors.secondary_font}` }}
          >
            IQD {parseFloat(price).toLocaleString("en-US")}
          </p>
        )}

        <div ref={ref1}></div>
      </div>
    </div>
  );
}
